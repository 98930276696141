.container{
    display:flex;
    max-width: 100%;
    padding: 0;
}
main{
    width: 100%;
    padding: 20px;
}
.sidebar{
    position: fixed;
    background: #18B9AF;
    color: #fff;
    height: 100vh;
    max-width :300px;
    /* transition: all 0.5s; */
    z-index: 101;
    overflow-y: auto;
    overflow-x: hidden;
}

/* .sidebar::-webkit-scrollbar {
    display:none
    width: 10px;
  } */

  .sidebar::-webkit-scrollbar {
    width: 11px;
  }
  .sidebar {
    scrollbar-width: thin;
    scrollbar-color: rgb(119, 65, 3);
  }
  .sidebar::-webkit-scrollbar-track {
    background: #18B9AF;
    border-radius: 6px;
  }
  .sidebar::-webkit-scrollbar-thumb {
    background-color: rgb(119, 65, 3);
    border-radius: 6px;
    border: 3px solid rgb(119, 65, 3);
  }

.top_section{
    display: flex;
    align-items:center;
    padding:30px 15px;
    border-bottom: 2px solid white;
}


.logo{
    font-size: 30px;
    padding-right: 8px;

}

.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}

.top_section img{
    flex: 0 0 20%;
    width: 23.5%;
}

.top_section .right_text{
    flex: 0 0 53.4%;
    width: 53.4%;
    font-size:19px;
}
.top_section .bars{
    flex: 0 0 17.6%;
    width: 17.6%;
}

.link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: color 0.3s;
    position: relative;
}
.link:hover{
    background:white;
    color: #18B9AF;
    transition: color 0.3s;
    border-right: 2px solid #18B9AF;


    
}
.active{
    background: white;
    color: #18B9AF;
    border-right: 2px solid #18B9AF;
}
.icon, .link_text{
    font-size: 18px;
}
.Downicon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
}
.Downicon.up{
    transform: translateY(-50%) rotate(180deg);
}
.user {
    width: 60px;
    position: absolute;
    background-color: transparent;
    top: 50%;
    right: 30px;
    padding: 12px 9px;
    border-radius: 10px;
    transform: translateY(-50%);
}
   
.user img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.user_wraper{
    position: relative;
    height: 86px;
    
}
/* .user_wraper .down_arrow{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: url(../../images/down-arrow.svg) no-repeat;
    back
} */
.user_wraper .down_arrow {
    width: 10px;
    height: 10px;
    background: url(../../images/down-arrow.svg) no-repeat;
    background-size: 100%;
    background-position: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.user_wraper .user_profile_list{
    position: absolute;
    display: none;
    top: 55px;
    right: -10px;
    width: 150px;
    background-color: #18B9AF;
    list-style: none;
    padding: 0;
    z-index: 101;
}
.user_wraper .user_profile_list .up_item {
    padding: 10px 15px;

}
.user_wraper .user_profile_list .up_item:not(:last-child){
    border-bottom: 1px solid #fff;
} 
.user_wraper .user_profile_list .up_item{
    cursor: pointer;
} 
.user_wraper .user_profile_list .up_item:hover{
    background-color: #fff;
    color: #18B9AF;
} 
.user_wraper .user_profile_list a {
    text-decoration: none;
}

.up_item{
    /* background-color: #18B9AF; */
    color:white
}

.user:hover .user_profile_list{
    display: block;
}
.user:hover .down_arrow{
    top: 30%;
    transform: rotate(180deg) translateY(-50%);
}

.user svg{
    width: 30px;
    height: 35px;
}
.menu_wrapper{
    width: 100%;
}
.top_navbar{
    background: #18B9AF;
    height: 82px;
    color: white;
    position: relative;

}
.user_name{
    position: absolute;
    background-color: transparent;
    top: 50%;
    right: 85px;
    padding: 7px;
    border-radius: 10px;
    transform: translateY(-50%);
    color: #000;
    font-size: 18px;
}

.btn-close{
    margin: 0 !important;
}
.link:hover{
    text-decoration: none;
}

.sidebar-open *{
    transition: all 0.5s;
    z-index: 100;
}

.sidebar-closed .top_section .logo, .sidebar-closed .top_section .right_text, .sidebar-closed .link_text{
    display: none;
    transition: all 0.5s;
}
.navLinkWrapper{
    overflow-y: auto;
}
/* 
.link_list{

} */