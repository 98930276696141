@import "rsuite/dist/rsuite.css";


.action__btn {
    margin: 0 2px;
    font-size: 14px;
}

.search_btn{
    width: 25% !important;
}
.form_design{
    max-width: 90%;
    margin: 0% auto;
}
.create_btn {
    margin: 5px 0;
}
.form_label{
    font-weight: bold;
}
.read {
    background-color: white;
    max-width: 50%;
    margin: auto;
    padding: 5%;
    border: 2px solid rgb(38, 41, 88);
    border-radius: 5px;
    position: absolute;
    top: 20%;
    left: 27%;
  }

  .read_back{
    max-width: 50%;
    margin: auto;
    border: 2px solid rgb(38, 41, 88);
    border-radius: 5px;
    position: absolute;
    bottom: -1px;
    right: -1px;
  }

  .dttable{
    font-size:14px;
  }

.loading {
    left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
}

.rs-picker-select-menu-item{
  color: black;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
  color:black
}

.tab-content .active {
    background-color: unset;
}

.ck-editor__editable_inline {
    min-height: 400px;
  }
  .viewEditor .ck-toolbar_grouping{
    display: none !important;
  } 
  .viewEditor .ck-editor__editable_inline{
    min-height: 200px;
  } 
  

input::-webkit-outer-spin-button,

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="number"]:focus {
    outline: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-appearance: textfield;
  }
  

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }
  .preloader {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(34, 34, 34, 0.47);
    z-index: 1000;
  }
  
  .preloader_inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgb(34 34 34 / 15%);
    z-index: 1000;
  }
  
  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .spin {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }

  .rs-checkbox-group{
    flex-direction: row;
    display: unset;
  }


  @media (max-width: 991.98px){
    .search_btn{
        width: 50% !important;
    }
    .kgEPYv{
        font-size: 12px !important;
    }
    /* .bnAwAJ{
        min-width: 400px !important;
    } */
    .action__btn {
        font-size: 13px;
    }
}
  @media (max-width: 1200px){
    .search_btn{
        width: 50% !important;
    }
}

  @media (max-width: 767.98px){
    .search_btn{
        width: 100% !important;
    }
    .ifOHjV {
        width: 800px !important;
    }
    .action__btn {
        font-size: 12px;
    }
}