*{
    margin:0;
    padding:0;
    text-decoration: none;
}

a{
    text-decoration: none;
    border: 0;
}

.cEMZEX{
    z-index: -1;
}

.toast-body{
    color: white;
}


/* Dashboard design css*/

.db_card{
    padding:  20px;  
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
}

.db_card .card_content{
    color: #fff;
    /* flex: 0 0 80%; */
    width:90%;
    display: flex;
    align-items: flex-end;
    justify-content: center; 
    
    flex-direction: column;
}
.db_card .card_icon{
    flex: 0 0 10%;
    width: 10%;
    color: #fff;
}
.db_card .card_icon svg{
    width: 100%;
    height: 100%;
}
.db_card .card_title{
    font-size: 22px;
    line-height: 51px;
}
.db_card .card_subtitle{
    font-size: 23px;
    line-height: 20px;
}

.db_card.bg-gradient-red {
    /* background: linear-gradient(87deg, #f5365c 0, #f56036 100%); */
    background: #F06292;
    /* box-shadow: 0px 3px 22px #c40000c4; */

}
.db_card.bg-gradient-green {
    /* background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%); */
    background: #26E7A6;
    /* box-shadow: 0px 3px 22px #02f089; */

}
.db_card.bg-gradient-pink {
    /* background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%); */
    background: #B19CFE;
    /* box-shadow: 0px 3px 22px #02f089; */

}
.db_card.bg-gradient-blue {
    /* background: linear-gradient(87deg, #11cdef 0, #1171ef 100%); */
    background: #0DCAF0;
    /* box-shadow: 0px 3px 22px #006eff ; */
}
@import "./common.css";